import {
  appSettingsErrorAction,
  appSettingsLoadedAction,
  requestAppSettingsAction,
  AppSettingsService,
  api,
  AppSettingsLanguageModel,
  AppSettingsLanguageAction,
  AppSettingsHtmlDirectionAction,
  AppSettingsWebsiteAction,
  AppSettingsSubsiteAction,
} from 'alg-ecom-frontend-core';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store/interfaces';
// import { SettingsService } from '../api/services/settings';
// import { State } from '../store';
// import {
//   masterDataErrorAction,
//   masterDataLoadedAction,
//   requestMasterDataAction,
// } from '../store/reducers/master-data';

export const useMasterData = (
  appSettingsService: AppSettingsService,
  franchise: string
) => {
  const appSettings = useSelector((state: State) => state.appSettingsState);
  const dispatch = useDispatch();
  const loadAppSettings = useCallback(async () => {
    dispatch(requestAppSettingsAction());
    try {
      const response: any = await appSettingsService.getAppSettings();
      const data = response?.data?.data;
      const languge: AppSettingsLanguageModel = data?.languagesList?.find(
        (item: AppSettingsLanguageModel) => item.languageLcidstring === 'en'
      );
      data.subsitesList = response?.data?.data?.subsitesList?.filter(
        (item: any) => item.storeName === franchise
      );
      data.websiteList = response?.data?.data?.websiteList?.filter(
        (item: any) => item.websiteName === franchise
      );
      dispatch(appSettingsLoadedAction(data));
      if (languge) {
        dispatch(AppSettingsLanguageAction(languge?.languageID));
        dispatch(
          AppSettingsHtmlDirectionAction(
            languge.languageLcidstring === 'en' ? 'ltr' : 'rtl'
          )
        );
      }

      dispatch(AppSettingsWebsiteAction(data.websiteList[0]?.websiteID));
      dispatch(AppSettingsSubsiteAction(data.subsitesList[0]?.storeID));
    } catch (e) {
      dispatch(appSettingsErrorAction(e));
    }
  }, [dispatch, appSettingsService]);
  useEffect(() => {
    if (!appSettings.loaded && !appSettings.loading) {
      loadAppSettings();
    }
  }, [appSettings, loadAppSettings]);

  return {
    ...appSettings,
  };
};
