export enum RouteKeys {
  Home = '/',
  TrimLEvel = '/trim-level/:year/:modelCode/',
  TrimLevelYear = '/trim-level-year/:modelId',
  EditionVariant = '/edition/modelyear/:year/model/:modelCode/trimcode/:trim/',
  BuildVehicle = '/build-vehicle/:id',
  PayNow = '/pay-now/',
  PaymentCallback = '/payment-callback/',
  Auth = '/auth/',
  ForgotPassword = '/auth/forgot-password',
  MyAccount = '/my-account',
  NotFound = '/page-not-found',
  Build = '/build',
  Adas = '/detail/tiggo-8-pro-adas',
  ProductDetail = '/detail/:model',
  News = '/news',
  NewsDetail = '/news/detail/:slug',
  Contact = '/contactus',
  Owners = '/owner_manual',
  Specs = '/specs_comp',
  Offers = '/offers',
  CampaignOffers = '/campaign-offers',
  OfferThankYou = '/offers/thank-you',
  QuickAccessThankYou = '/:quickAccess/thank-you',
  Insurance = '/insurance',
  Finance = '/finance',
  FinanceOpt = '/financeOpt',
  AboutUs = '/about-us',
  PrivacyPolicy = '/privacy-policy',
  Terms = '/terms',
  Disclaimer = '/disclaimer',
  Service = '/service',
  Offer = '/offers',
  WelcomeForm = '/enquire-now',
  VirtualShowroom = '/virtual-showroom',
  TestDriveCampaign = '/testdrivebooking',
  PaymentLink = '/paymentrequest',
  PaymentLinkSuccess = '/paymentrequest-success',
  quickFormTestDrive = '/:modelCode/test-drive',
  quickFormRequestCallback = '/:modelCode/request-callback',
  campaignEnquire = '/enquire-offers',
  DiscountApproval = '/approve-discount',
  ApprovalStatus = '/approve-discount/approvalStatus',
  CustomerPaymentLink = '/customer-paymentrequest',
  CustomerPaymentLinkSuccess = '/customer-paymentrequest-success',
}
