import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FullPageLoader } from '../../components/loader';
import { RouteKeys } from './route-keys';

const RedirectIfAuthenticatedRoute = lazy(
  () => import('./RedirectIfAuthenticatedRoute')
);
const UnAuthenticateRoute = lazy(() => import('./UnAuthenticatedRoute'));
const AuthenticateRoute = lazy(() => import('./AuthenticateRoute'));
const Home = lazy(() => import('../home'));
const NotFound = lazy(() => import('../notfound'));
const TrimLevel = lazy(() => import('../trim-level'));
const EditionVarient = lazy(() => import('../edition-variant'));
const BuildVehicle = lazy(() => import('../build-vehicle'));
const Landing = lazy(() => import('../landing'));
const PaymentSelection = lazy(() => import('../payment/PayNow'));
const PaymentSuccess = lazy(() => import('../payment/PaymentSuccess'));
const AuthPage = lazy(() => import('../auth/AuthPage'));
const MyAccount = lazy(() => import('../dashboard'));
const ProductDetail = lazy(() => import('../product-detail/ProductDetail'));
const NewsListing = lazy(() => import('../news/NewsListing'));
const NewsDetail = lazy(() => import('../news/NewsDetail'));
const Contact = lazy(() => import('../cms/contact'));
const Owners = lazy(() => import('../cms/owners'));
const Specs = lazy(() => import('../cms/specs'));
const Insurance = lazy(() => import('../cms/insurance'));
const Finance = lazy(() => import('../cms/finance'));
const FinanceOpt = lazy(() => import('../cms/financeOpt'));
const PrivacyPolicy = lazy(() => import('../cms/privacypolicy'));
const Disclaimer = lazy(() => import('../cms/disclaimer'));
const AboutUs = lazy(() => import('../cms/about'));
const Service = lazy(() => import('../cms/service'));
const Offer = lazy(() => import('../offer'));
const Terms = lazy(() => import('../cms/terms'));
const WelcomeForm = lazy(() => import('../cms/welcomeform'));
const SubRoutes = lazy(() => import('./SubRoutes'));
const VirtualTour = lazy(() => import('../cms/VirtualTour'));
const TestDriveCampaign = lazy(
  () => import('../cms/TestDriveCampaign/TestDriveCampaign')
);

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<FullPageLoader text={'Loading...'} />}>
        <Switch>
          <Route path={'/:lang'} component={SubRoutes} />

          <Redirect
            to={{
              pathname: `en${RouteKeys.Home}`,
            }}
            from={RouteKeys.Home}
          />

          {/* <Route exact path={RouteKeys.Home} component={Home} /> */}
          <Route exact path={RouteKeys.NotFound} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
