import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Root from './containers/routes/Root';
import { store, persistor } from './store';
import 'translation';
import ReactGA from 'react-ga';
const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID;
const GOOGLE_ANALYTICS_ID2 = process.env.GOOGLE_ANALYTICS_ID2;
if (GOOGLE_ANALYTICS_ID && GOOGLE_ANALYTICS_ID2) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  ReactGA.initialize(
    [
      {
        trackingId: GOOGLE_ANALYTICS_ID,
        gaOptions: {
          name: 'tracker1',
        },
      },
      {
        trackingId: GOOGLE_ANALYTICS_ID2,
        gaOptions: { name: 'tracker2' },
      },
    ],
    { alwaysSendToDefaultTracker: false }
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root store={store} persistor={persistor} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
