export default class Config {
  static readonly MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1Ijoic2hhcmFmdWRoZWVuIiwiYSI6ImNrNHNsMGpjYTA1dDAzZG93a2tnNzV3cnEifQ.hZ0R4NowNN6YzbhOCZFGQg';
  static GOOGLE_ANALYTICS_ID = 'DC-9830108';
  static franchise = 'chery';
  static FACEBOOK_APP_ID = '863867621011323';
  static WHATSAPP_NO = '+9651831111';
  static GOOGLE_CLIENT_ID =
    '299150888981-1bg6tru53ddcgk6551dmje2oa1skfjjm.apps.googleusercontent.com';
  static ShuwaikhShowroomId = 22;
}
