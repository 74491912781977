import React from 'react';
import { withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import Routes from './routes/Routes';
import { useMasterData } from 'hooks/useMasterData';
import { api } from 'alg-ecom-frontend-core';
import FullPageLoader from 'components/loader/full-page-loader';
import Config from 'config';
import { useCMS } from 'store/cms/useCMS';
// import '../styles/base.scss';

const App: React.FC = () => {
  const masterData = useMasterData(api.appSettings, Config.franchise);

  return (
    <div>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={false}
      />

      {(!masterData.loaded || masterData.loading) && <FullPageLoader />}
      <Routes />
    </div>
  );
};

// without using withRouter, route changes only reflect in url but not on page.
export default withRouter(App);
